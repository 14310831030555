import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import { uniqBy } from 'lodash'
import React, { memo, useState } from 'react'

import { Filter } from './Filter'
import { Offer, Props as OfferProps } from './Offer'

export interface Props {
  languageCode: string
  offers: OfferProps[]
}

export const Offers = memo(function Offers({ languageCode, offers }: Props) {
  if (offers.length < 1) {
    return null
  }

  const [filteredCategory, setFilteredCategory] = useState('all')

  const [items, setItems] = useState(offers)

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {offers.length > 1 ? (
          <>
            <Media greaterThanOrEqual="ipadHorizontal">
              <Filters dial={5} row wrap>
                <Filter
                  active={filteredCategory === 'all'}
                  category="all"
                  title={useVocabularyData('all', languageCode)}
                  onClick={() => {
                    setFilteredCategory('all')
                    setItems(offers)
                  }}
                />
                {uniqBy(offers, 'category').map((item, index) => {
                  if (!item.category) {
                    return null
                  }

                  const category = item.category

                  return category ? (
                    <Filter
                      active={filteredCategory === category}
                      category={category}
                      key={index}
                      onClick={(e) => {
                        setFilteredCategory(
                          e.target.getAttribute('data-category'),
                        )

                        setItems(
                          offers.filter(
                            (i) =>
                              i.category ===
                              e.target.getAttribute('data-category'),
                          ),
                        )
                      }}
                      title={item.category}
                    />
                  ) : undefined
                })}
              </Filters>
            </Media>

            <Media lessThan="ipadHorizontal">
              <Select
                onChange={(e) => {
                  setFilteredCategory(e.target.value)
                  if (e.target.value === 'all') {
                    setItems(offers)
                  } else {
                    setItems(
                      offers.filter((i) => i.category === e.target.value),
                    )
                  }
                }}
              >
                <Option value="all">
                  {useVocabularyData('all', languageCode)}
                </Option>
                {uniqBy(offers, 'category').map((item, index) => {
                  if (!item.category) {
                    return null
                  }

                  const category = item.category

                  return category ? (
                    <Option key={index} value={category}>
                      {item.category}
                    </Option>
                  ) : undefined
                })}
              </Select>
            </Media>
          </>
        ) : null}

        <Wrapper row wrap>
          {uniqBy(items, 'title').map((item, index) => (
            <Offer key={index} {...item} />
          ))}
        </Wrapper>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 8.125rem 0 17.0625rem;

  @media (max-width: 1023px) {
    padding: 5rem 0 6.25rem;
  }
`

const Filters = styled(FlexBox)`
  border-bottom: 0.0625rem solid
    ${({ theme }) => theme.colors.variants.neutralLight1};
  padding: 0 11.944vw 2.125rem;
`

const Select = styled.select`
  width: calc(100% - 4rem);
  height: 3.75rem;
  background: url('/chevron-down-dark.svg') no-repeat calc(100% - 1.25rem)
    center ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  margin: 0 2rem;
  padding: 0 1.25rem;
`

const Option = styled.option``

const Wrapper = styled(FlexBox)`
  margin: 3.625rem -1.5rem 0 0;
  padding: 0 11.944vw;

  > a {
    width: calc(50% - 1.5rem);
    margin: 1.5rem 1.5rem 0 0;
    &:nth-of-type(even) {
      transform: translateY(3.75rem);
    }
  }

  @media (max-width: 1199px) {
    padding: 0 2rem;
  }

  @media (max-width: 1023px) {
    margin: 1.75rem 0 0 0;

    > a {
      width: 100%;
      margin: 0.125rem 0 0 0;
      &:nth-of-type(even) {
        transform: none;
      }
    }
  }
`
